import { useReactiveVar } from '@apollo/client'
import { blockLeadsDisplay } from 'apollo'
import AddressBlock, { overrideAddressBlockStyle, useAddressStore } from 'components/addressBlock'
import { useDoctorStore } from 'components/doctorBlock/hooks/state'
import { StyledErrorWrapper } from 'components/layoutUi'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { useHealthTapStore } from 'modules/sleepNewLeads/State/healthTapStore'
import React from 'react'
import ComeBackLaterLink from '../ComeBackLaterLink'

function AddressPage({ onSubmit, displaySubmitError, ...pageNavigatorProps } : { onSubmit: () => void, displaySubmitError: boolean } & any ) {
  const { addressConfirmed, setCurrentView } = useAddressStore()
  const { isDoctorComplete } = useDoctorStore()
  const { healthtapSelected } = useHealthTapStore()
  const leadsDisplayBlocked = useReactiveVar( blockLeadsDisplay )


  overrideAddressBlockStyle({
    headerStyle: `font-semibold font-inter text-[#0057A8] text-center sm:text-3xl lg:text-[42px] sm:pb-4 lg:pb-7`,
    containerStyle: ``,
    buttonStyle: `btn btn-secondary mx-auto`,
    wrapperStyle: `max-w-none text-center`,
    view: {
      editActionClassName: `underline cursor-pointer text-deepSleepBlue`
    }
  })

  const handleSuccessfulAddressConfirmation = () => {
    if ( !leadsDisplayBlocked ) {
      pageNavigatorProps.onHandleChange({
        addressAcceptedFlag: `1`
      })
    }

    setCurrentView( `complete` )
  }

  return (
    <div className="sm:mx-auto lg:mx-0 max-w-[672px] relative">
      <AddressBlock
        addressType=""
        subHeaderText={`Your order will only be shipped after you have reviewed your estimated cost and confirmed your order.`}
        headerText={`Your Shipping Address`}
        onSuccessfulConfirm={handleSuccessfulAddressConfirmation}
      />
      {displaySubmitError && (
        <StyledErrorWrapper>
          {`We're sorry but our servers have encountered an issue processing this submission. Please call a patient care specialist at ${AF_SUPPORT_PHONE_NUMBER} for help.`}
        </StyledErrorWrapper>
      )}
      <div className="mx-auto w-80 mt-6 space-y-6">
        <button
          className="btn btn-primary"
          onClick={onSubmit}
          disabled={!isDoctorComplete || !addressConfirmed || healthtapSelected === null}
        >
          {`Submit`}
        </button>
      </div>
      <ComeBackLaterLink className="a py-5 w-full mx-auto underline decoration-deepSleepBlue" />
    </div>
  )
}

export default AddressPage