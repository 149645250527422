import React from 'react'
import { Builder } from '@builder.io/react'

interface SocialImage {
  imageUrl: string;
  altText?: string;
}

interface imagesDisplayProps {
  heading: string;
  images: SocialImage[];
}

const imagesDisplay = ({
  heading = `What Our Patients Say...`,
  images = []
}: imagesDisplayProps ): JSX.Element => {

  return (
    <div className="py-12 px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-center text-2xl md:text-4xl italic font-sans font-extrabold text-aeroflowNavy">
          {heading}
        </h2>
        {images.length > 0 &&
        <div className="flex flex-wrap items-center justify-center gap-5 max-w-5xl">
          {images.map( ( img, index ) => {
            return (
              <div key={index} className="flex items-center justify-center">
                <img
                  src={img.imageUrl}
                  alt={img.altText || `Social media testimonial ${index + 1}`}
                />
              </div>
            )
          })}
        </div>}
      </div>
    </div>
  )
}

Builder.registerComponent( imagesDisplay, {
  name: `Social Images Display`,
  inputs: [
    {
      name: `heading`,
      type: `string`,
      defaultValue: `What Our Patients Say...`,
      required: true
    },
    {
      name: `images`,
      type: `list`,
      subFields: [
        {
          name: `imageUrl`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `webp` ],
          required: true,
          helperText: `Upload an image`
        },
        {
          name: `altText`,
          type: `string`,
          helperText: `Alternative text for the image (for accessibility)`
        }
      ]
    }
  ]
})

export default imagesDisplay
