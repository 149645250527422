import {
  airCurve, airSense10, airSense11, dreamStationBipap, dreamStationCpap, driveDevilbiss, lunaIIAutoWithHumidifier, remstarSystemOneBipap, respironicsDreamstation2AutoCpap,
  s9, systemOneBipap, threeBResmartBipap, airFitF10, airFitF20, airFitF30, airFitF30i, airFitN10,
  airFitN20, airFitN30, airFitN30i, airFitP10, airFitP30i, airTouchN20, amaraGel, amaraView, brevida, comfortgelBlue, dreamway, dreamwearFull, dreamwearGel, dreamwearNasal,
  dreamwwispNasalCpap, eson2, esonNasal, evoraNasal, flexifit432, formaFull, mirageQuattro, nuanceProGel, opusNasalPillow, pilairaNasal, quattroAirFull, resmedMirageFx,
  resmedMirageSwift, simplusFull, swiftFxNasal, viteraFull, bFisherAndPaykel, bPhilips, bResmed, bRoscoe, airTouchF20, lunaG2, resventCpap, resventBiPap, bBleepSleep, bDriveDeviliss, bResvent, lunaG3Auto, lunaG3Bipap, bReactHealth, quattroFxFull,
  dreamWearNasalPillow, eclipseNasalPillow, evoraFullFace, airFitF40, flexiFit407Nasal, fpSoloNasal, mirageActivaLtNasal, swiftFxNasalPillow, wispClearSiliconeNasal, zestQNasal
} from "resources/images"

export const SNL_MASK_TYPE_PATH = `/new-patient-intake/sleep/mask-type`
export const SNL_MASK_BRAND_PATH = `/new-patient-intake/sleep/mask-brand`
export const SNL_MASK_MODEL_PATH = `/new-patient-intake/sleep/mask-model`
export const SNL_MACHINE_BRAND_PATH = `/new-patient-intake/sleep/machine-brand`
export const SNL_MACHINE_MODEL_PATH = `/new-patient-intake/sleep/machine-model`
export const SNL_ORDER_SUMMARY_PATH = `/new-patient-intake/sleep/order-summary`
export const SNL_STEPS_PATH = `/new-patient-intake/sleep/steps`
export const SNL_STEPS_PATH_MASK = `/new-patient-intake/sleep/steps-mask`
export const SNL_STEPS_PATH_MACHINE = `/new-patient-intake/sleep/steps-machine`
export const SNL_STEPS_PATH_ACCESSORIES = `/new-patient-intake/sleep/steps-accessories`
export const SNL_BASE_PATH = `/new-patient-intake/sleep`
export const SNL_STEPS_DOCTOR_LOOKUP_PATH = `/new-patient-intake/sleep/steps-doctor-address`
export const SNL_COLLECTION_PATH = `/new-patient-intake/sleep/doctor-and-shipping-address`
export const SNL_HEALTHTAP_PATH = `/new-patient-intake/sleep/healthtap`
export const SNL_DOCTOR_PATH = `/new-patient-intake/sleep/doctor`
export const SNL_ADDRESS_PATH = `/new-patient-intake/sleep/address`
export const SNL_MASK_FITTING_PATH = `/new-patient-intake/sleep/mask-fitting`
export const SNL_MACHINE_SHORTAGE_PATH = `/new-patient-intake/sleep/machine-shortage`
export const SNL_THANK_YOU_PATH = `/new-patient-intake/sleep/thank-you`
export const SNL_CONFIRMATION_PATH = `/new-patient-intake/sleep/confirmation`

export const toolTipContent = `The brand and model name of your mask are usually printed on the plastic portion of the mask frame and/or your headgear.`

export const ineligibleCopy = `Unfortunately, based on your answers, you are not eligible for a PAP machine through insurance at this time. All insurances typically require that CPAP/BiPAP machines can only be replaced every 5 years. If you'd like to still move forward with a fresh order of PAP supplies (i.e. mask, tubing, etc), click the button below.`

export const repeatSAPCopy = `Oops! It looks like you've already completed the sleep apnea profile form. If you have any questions or concerns about your order, please contact our customer service team at `

export const pageNames = [
  SNL_MASK_TYPE_PATH,
  SNL_MASK_BRAND_PATH,
  SNL_MASK_MODEL_PATH,
  SNL_MACHINE_BRAND_PATH,
  SNL_MACHINE_MODEL_PATH,
  SNL_ORDER_SUMMARY_PATH,
  SNL_COLLECTION_PATH,
  SNL_THANK_YOU_PATH
]

export const separatePageNames = [
  SNL_MASK_TYPE_PATH,
  SNL_MASK_BRAND_PATH,
  SNL_MASK_MODEL_PATH,
  SNL_MACHINE_BRAND_PATH,
  SNL_MACHINE_MODEL_PATH,
  SNL_ORDER_SUMMARY_PATH,
  SNL_HEALTHTAP_PATH,
  SNL_DOCTOR_PATH,
  SNL_ADDRESS_PATH,
  SNL_THANK_YOU_PATH
]

export const reorderedSeparatePageNames = [
  SNL_MASK_TYPE_PATH,
  SNL_MASK_BRAND_PATH,
  SNL_MASK_MODEL_PATH,
  SNL_MACHINE_BRAND_PATH,
  SNL_MACHINE_MODEL_PATH,
  SNL_ORDER_SUMMARY_PATH,
  SNL_DOCTOR_PATH,
  SNL_HEALTHTAP_PATH,
  SNL_ADDRESS_PATH,
  SNL_THANK_YOU_PATH
]

export const maskStepsUrlMap = {
  cpapMaskType: SNL_MASK_TYPE_PATH,
  cpapMaskBrand: SNL_MASK_BRAND_PATH,
  cpapMaskModel: SNL_MASK_MODEL_PATH
}


export const progressData = {
  machine: [
    `cpapMachineBrand`,
    `cpapMachineModel`,
    `lastOrderCpapMachine`,
    `intentOrderNewEquipment`
  ],
  mask: [
    `cpapMaskType`,
    `cpapMaskBrand`,
    `cpapMaskModel`,
    `cpapMaskSize`,
    `lastOrderCpapMask`,
    `lastOrderCpapMaskHeadgear`,
    `satisfiedWithCurrentMask`
  ],
  other: [
    `tubingType`,
    `lastOrderCpapSupplies`
  ],
  lookup: [
    `addressAcceptedFlag`,
    `doctorAcceptedFlag`
  ]
}

export const machineEligibleProgress = [
  `cpapMachineBrand`,
  `cpapMachineModel`,
  `lastOrderCpapMachine`,
  `intentOrderNewEquipment`
]

export const machineIneligibleProgress = [
  `lastOrderCpapMachine`,
  `intentOrderNewEquipment`
]

export const machineImages =
[
  {
    brand: `RESMED`,
    machine: `AirSense 10 (All Models)`,
    imgSrc: [ airSense10 ]
  },
  {
    brand: `RESMED`,
    machine: `AirSense 11 CPAP`,
    imgSrc: [ airSense11 ]
  },
  {
    brand: `RESMED`,
    machine: `AirCurve 10 (S, ASV, ST, Vauto, BiPAP)`,
    imgSrc: [ airCurve ]
  },
  {
    brand: `RESMED`,
    machine: `S9 (All Models)`,
    imgSrc: [ s9 ]
  },
  {
    brand: `RESMED`,
    machine: `S9 VPAP or BiPAP`,
    imgSrc: [ s9 ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    machine: `DreamStation 2 CPAP`,
    imgSrc: [ respironicsDreamstation2AutoCpap ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    machine: `DreamStation CPAP (All Models)`,
    imgSrc: [ dreamStationCpap ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    machine: `DreamStation BiPAP (All Models)`,
    imgSrc: [ dreamStationBipap ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    machine: `RESPIRONICS SYSTEM ONE AUTO CPAP`,
    imgSrc: [ systemOneBipap ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    machine: `System One BiPAP`,
    imgSrc: [ systemOneBipap ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    machine: `REMstar System One BiPAP`,
    imgSrc: [ remstarSystemOneBipap ]
  },
  {
    brand: `3B MEDICAL`,
    machine: `Luna II CPAP`,
    imgSrc: [ lunaG2 ]
  },
  {
    brand: `3B MEDICAL`,
    machine: `LUNA G2 AUTO CPAP W/ WIFI`,
    imgSrc: [ lunaIIAutoWithHumidifier ]
  },
  {
    brand: `3B MEDICAL`,
    machine: `LUNA G3 AUTO  W/ INTEGRATED HT & MODEM`,
    imgSrc: [ lunaG3Auto ]
  },
  {
    brand: `3B MEDICAL`,
    machine: `3B RESmart BiPAP`,
    imgSrc: [ threeBResmartBipap ]
  },
  {
    brand: `3B MEDICAL`,
    machine: `LUNA G3 BIPAP WITHOUT MODEM`,
    imgSrc: [ lunaG3Bipap ]
  },
  {
    brand: `DEVILBISS`,
    machine: `Devilbiss Intellipap BiPAP`,
    imgSrc: [ driveDevilbiss ]
  },
  {
    brand: `DEVILBISS`,
    machine: `Devilbiss Intellipap CPAP`,
    imgSrc: [ driveDevilbiss ]
  },
  {
    brand: `Resvent Medical Technology Co.`,
    machine: `RESVENT 20A AUTO CPAP`,
    imgSrc: [ resventCpap ]
  },
  {
    brand: `Resvent Medical Technology Co.`,
    machine: `RESVENT AUTO BIPAP WITH WIFI MODEM`,
    imgSrc: [ resventBiPap ]
  },
  {
    brand: `ROSCOE MEDICAL`,
    machine: `3B RESmart BiPAP`,
    imgSrc: [ threeBResmartBipap ]
  }
]

export const maskImages =
[
  {
    brand: `RESMED`,
    mask: `AirFit P10 Dual Pillow Nasal Mask`,
    imgSrc: [ airFitP10 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit N10 Nasal Mask`,
    imgSrc: [ airFitN10 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit N20 Nasal Mask`,
    imgSrc: [ airFitN20 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit N30 Nasal Mask`,
    imgSrc: [ airFitN30 ]
  },
  {
    brand: `RESMED`,
    mask: `AirTouch N20 Nasal Mask`,
    imgSrc: [ airTouchN20 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit N30i Standard Frame Nasal Mask`,
    imgSrc: [ airFitN30i ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit P30i Nasal Mask`,
    imgSrc: [ airFitP30i ]
  },
  {
    brand: `RESMED`,
    mask: `Mirage FX Nasal Mask`,
    imgSrc: [ resmedMirageFx ]
  },
  {
    brand: `RESMED`,
    mask: `Mirage Swift II Nasal Mask`,
    imgSrc: [ resmedMirageSwift ]
  },
  {
    brand: `RESMED`,
    mask: `Swift FX Nasal Mask`,
    imgSrc: [ swiftFxNasal ]
  },
  {
    brand: `RESMED`,
    mask: `Swift FX Nasal Pillow Mask`,
    imgSrc: [ swiftFxNasalPillow ]
  },
  {
    brand: `RESMED`,
    mask: `Mirage Activa LT Nasal Mask`,
    imgSrc: [ mirageActivaLtNasal ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit F20 Full Face Mask`,
    imgSrc: [ airFitF20 ]
  },
  {
    brand: `RESMED`,
    mask: `AirTouch F20 Full Face Mask`,
    imgSrc: [ airTouchF20 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit F10 Full Face Mask`,
    imgSrc: [ airFitF10 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit F30i Full Face Mask`,
    imgSrc: [ airFitF30i ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit F30 Full Face Mask`,
    imgSrc: [ airFitF30 ]
  },
  {
    brand: `RESMED`,
    mask: `AirFit F40 Full Face Mask`,
    imgSrc: [ airFitF40 ]
  },
  {
    brand: `RESMED`,
    mask: `Mirage Quattro Full Face Mask`,
    imgSrc: [ mirageQuattro ]
  },
  {
    brand: `RESMED`,
    mask: `Quattro Air Full Face Mask`,
    imgSrc: [ quattroAirFull ]
  },
  {
    brand: `RESMED`,
    mask: `Quattro FX Full Face Mask`,
    imgSrc: [ quattroFxFull ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `DreamWear Nasal Mask`,
    imgSrc: [ dreamwearNasal ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `DreamWear Nasal Pillow Mask`,
    imgSrc: [ dreamWearNasalPillow ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `Nuance Pro Gel Nasal Mask`,
    imgSrc: [ nuanceProGel ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `DreamWisp Nasal Mask`,
    imgSrc: [ dreamwwispNasalCpap ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `ComfortGel Blue Nasal Mask`,
    imgSrc: [ comfortgelBlue ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `Wisp Clear Silicone Nasal CPAP Mask`,
    imgSrc: [ wispClearSiliconeNasal ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `DreamWear Gel Nasal Mask`,
    imgSrc: [ dreamwearGel ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `DreamWear Gel Pillow Mask`,
    imgSrc: [ dreamwearGel ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `Amara View Full Face Mask`,
    imgSrc: [ amaraView ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `Amara Gel Full Face Mask`,
    imgSrc: [ amaraGel ]
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    mask: `DreamWear Full Face Mask`,
    imgSrc: [ dreamwearFull ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Brevida Nasal Mask - Xsmall to Small`,
    imgSrc: [ brevida ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Brevida Nasal Mask - Medium to Large`,
    imgSrc: [ brevida ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Eson 2 Nasal Mask`,
    imgSrc: [ eson2 ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Evora Nasal Mask`,
    imgSrc: [ evoraNasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Eson Nasal Mask`,
    imgSrc: [ esonNasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `FlexiFit 407 Nasal Mask`,
    imgSrc: [ flexiFit407Nasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Solo Nasal Mask`,
    imgSrc: [ fpSoloNasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Opus Nasal Pillow Mask`,
    imgSrc: [ opusNasalPillow ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Pilairo Nasal Mask`,
    imgSrc: [ pilairaNasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Evora Nasal Mask Wide`,
    imgSrc: [ evoraNasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Zest Q Nasal Mask`,
    imgSrc: [ zestQNasal ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `FlexiFit 432 Full Face Mask`,
    imgSrc: [ flexifit432 ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Forma Full Face Mask`,
    imgSrc: [ formaFull ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Simplus Full Face Mask`,
    imgSrc: [ simplusFull ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Vitera Full Face Mask`,
    imgSrc: [ viteraFull ]
  },
  {
    brand: `FISHER & PAYKEL`,
    mask: `Evora Full Face mask`,
    imgSrc: [ evoraFullFace ]
  },
  {
    brand: `Bleep LLC`,
    mask: `Eclipse Nasal Pillow Mask`,
    imgSrc: [ eclipseNasalPillow ]
  },
  {
    brand: `Bleep LLC`,
    mask: `Dreamway Nasal Mask - Standard Size`,
    imgSrc: [ dreamway ]
  }
]

export const brandImages = [
  {
    brand: `RESMED`,
    imgSrc: bResmed
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    imgSrc: bPhilips
  },
  {
    brand: `3B MEDICAL`,
    imgSrc: bReactHealth
  },
  {
    brand: `Resvent Medical Technology Co.`,
    imgSrc: bResvent
  },
  {
    brand: `DEVILBISS`,
    imgSrc: bDriveDeviliss
  },
  {
    brand: `FISHER & PAYKEL`,
    imgSrc: bFisherAndPaykel
  },
  {
    brand: `Bleep LLC`,
    imgSrc: bBleepSleep
  },
  {
    brand: `ROSCOE MEDICAL`,
    imgSrc: bRoscoe
  }
]

export const machineBrands = [
  {
    brand: `RESMED`,
    imgSrc: bResmed
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    imgSrc: bPhilips
  },
  {
    brand: `3B MEDICAL`,
    imgSrc: bReactHealth
  },
  {
    brand: `Resvent Medical Technology Co.`,
    imgSrc: bResvent
  },
  {
    brand: `DEVILBISS`,
    imgSrc: bDriveDeviliss
  }
]

export const maskBrands = [
  {
    brand: `RESMED`,
    imgSrc: bResmed
  },
  {
    brand: `PHILIPS RESPIRONICS`,
    imgSrc: bPhilips
  },
  {
    brand: `FISHER & PAYKEL`,
    imgSrc: bFisherAndPaykel
  },
  {
    brand: `Bleep LLC`,
    imgSrc: bBleepSleep
  }
]

export const maskTypeImages = [
  {
    type: `Full Face`,
    imgSrc: airTouchF20
  },
  {
    type: `Nasal or Nasal Pillow`,
    imgSrc: brevida
  }
]


export const initialSNLFormValuesState = {
  cpapMaskType: ``,
  cpapMaskDmeID: ``,
  cpapMaskBrand: ``,
  cpapMaskModel: ``,
  cpapMaskSize: ``,
  lastOrderCpapMask: ``,
  lastOrderCpapMaskHeadgear: ``,
  satisfiedWithCurrentMask: ``,

  cpapMachineDmeID: null,
  cpapMachineBrand: ``,
  cpapMachineModel: ``,
  lastOrderCpapMachine: ``,
  intentOrderNewEquipment: ``,

  tubingDmeID: ``,
  tubingType: ``,
  lastOrderCpapSupplies: ``,

  addressAcceptedFlag: ``,
  doctorAcceptedFlag: ``
}
