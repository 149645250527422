import React from 'react'
import {
  createClient,
  Environment,
  KameleoonProvider
} from '@kameleoon/react-sdk'
import KameleoonInit from './KameleoonInit'

function KameleoonSetup({ children } : { children: React.ReactNode }) {
  const client = createClient({
    siteCode: process.env.REACT_APP_KAMELEOON_SITE_CODE || ``,
    configuration: {
      updateInterval: 1,
      environment: process.env.REACT_APP_ENVIRONMENT === `production` ? Environment.Production : process.env.REACT_APP_ENVIRONMENT === `stage` ? Environment.Staging : Environment.Development
    }
  })

  return (
    <KameleoonProvider client={client}>
      <KameleoonInit />
      {children}
    </KameleoonProvider>
  )
}

export default KameleoonSetup