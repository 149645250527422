import { useFeatureFlag, useInitialize, useVisitorCode } from '@kameleoon/react-sdk'
import { useEffect, useState } from 'react'
import { useKameleoonStore } from 'stores/kameleoonStore'


export function useGetFeatureFlagVariation( featureFlagId: string, defaultVariation = ``, forceVariation = undefined, experimentId = undefined ) {
  const [ variation, setVariation ] = useState<string>( defaultVariation )
  const [ engineCode, setEngineCode ] = useState<string>( `` )
  // used when kameleoon is not initialized at the time of retrieving a variation
  const [ variationLoading, setVariationLoading ] = useState<boolean>( true )
  const { isInitialized, initialize } = useInitialize()
  const { isFeatureFlagActive, getVariation, setForcedVariation, getEngineTrackingCode } = useFeatureFlag()
  const { getVisitorCode } = useVisitorCode()
  const { visitorCode } = useKameleoonStore()

  // used to allow QA to test out different variations
  const searchParams = new URLSearchParams( window.location.search )
  const clientForcedVariation = searchParams.get( `kameleoonVariation` )

  const getFeatureVariation = ( visitorCode: string ) => {
    try {
      if ( ( forceVariation || clientForcedVariation ) && experimentId ) {
        setForcedVariation({
          visitorCode,
          variationKey: forceVariation ?? clientForcedVariation,
          experimentId,
          forceTargeting: true
        })
        setVariation( ( forceVariation || clientForcedVariation ) as string )
        setEngineCode( getEngineTrackingCode( visitorCode ) )
      } else if ( isFeatureFlagActive({
        visitorCode,
        featureKey: featureFlagId
      }) ) {
        const activeVariation = getVariation({
          visitorCode,
          featureKey: featureFlagId
        })
        if ( activeVariation?.key ) {
          setVariation( forceVariation ?? clientForcedVariation ?? activeVariation.key )
          setEngineCode( getEngineTrackingCode( visitorCode ) )
        }
      }

      setVariationLoading( false )
    } catch ( e ) {
      console.error( `Error getting variation for feature flag`, e )
    }
  }

  useEffect( () => {
    // -- Insert tracking code into the page
    if ( engineCode ) {
      const script = document.createElement( `script` )
      script.textContent = engineCode

      document.body.appendChild( script )

      // -- Remove script from the page
      return () => {
        document.body.removeChild( script )
      }
    }
  }, [ engineCode ] )

  useEffect( () => {
    // if kameleoon isn't initialized yet then poll until it is initialized
    // once initialized get the variation for the visitor
    if ( !isInitialized() || !visitorCode ) {
      const initializedCheck = setTimeout( () => {
        if ( isInitialized() && visitorCode ) {
          getFeatureVariation( visitorCode )
          clearTimeout( initializedCheck )
        }
      }, 1000 )
    } else getFeatureVariation( visitorCode )
  }, [ isInitialized, initialize, visitorCode, getVariation, getVisitorCode ] )

  return {
    variation,
    variationLoading
  }

}