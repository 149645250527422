import React from 'react'
import { Builder } from '@builder.io/react'
import { ValuePropositionItem } from './components/ValuePropositionItem'
import { ValuePropositionItemTypes } from './types'

type ValuePropositionItemProps = {
  items: ValuePropositionItemTypes[];
  title: string;
}

export const ValueProposition = ({ items, title }: ValuePropositionItemProps ): JSX.Element => {
  return (
    <>
      {items && (
        <div className="text-center sm:py-8 md:py-[46px] lg:w-10/12 lg:my-0 lg:mx-auto xl:w-8/12 p-4">
          <h3 className="font-normal sm:text-[28px] md:text-5xl sm:mb-5 md:pb-[70px] text-aeroflowNavy">{title}</h3>
          <div className="grid justify-center sm:grid-cols-2 lg:grid-cols-4 gap-[22px] sm:max-w-lg lg:max-w-[1020px] mx-auto">
            {items.map( ( item ) => (
              <ValuePropositionItem key={item.content} {...item} />
            )
            )}
          </div>
        </div>
      )}
    </>
  )
}

Builder.registerComponent( ValueProposition, {
  name: `ValueProposition`,
  inputs: [
    {
      name: `title`,
      type: `text`,
      defaultValue: `The Aeroflow Difference`
    },
    {
      name: `items`,
      type: `list`,
      subFields: [
        {
          name: `imgSrc`,
          type: `file`,
          allowedFileTypes: [ `jpeg`, `jpg`, `png`, `svg`, `webp` ]
        },
        {
          name: `altText`,
          type: `text`
        },
        {
          name: `content`,
          type: `text`
        }
      ]
    }
  ]
})

export default ValueProposition