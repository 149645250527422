import { Modal } from 'components/modal'
import { useTriggerSleepConfetti } from 'hooks/useTriggerSleepConfetti'
import { useEffect, useState } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { icoXAob } from 'resources/images'
import { useNavigate } from 'react-router-dom'
import { ENABLE_ACCOUNT_PATH } from 'routes'
import { useQualifyFormStore } from 'modules/qualifyForm/state'
import { useCongratsModalStore } from 'stores/modalStore'

function ComeBackLaterModal({ handleNavigate, confetti = false } : { handleNavigate?: () => void, confetti?: boolean }) {
  const { onInit, triggerZs } = useTriggerSleepConfetti()
  const { formFields } = useQualifyFieldsStore()
  const navigate = useNavigate()
  const { resetPasswordToken } = useQualifyFormStore()

  const { hasShownCongratsModal, setHasShownCongratsModal } = useCongratsModalStore()

  const medicare =
    formFields.insuranceType?.toLowerCase() === `medicare`

  const [ congratsModal, setCongratsModal ] = useState( medicare && !hasShownCongratsModal )

  // congratulations and confetti if user has medicare and hasn't seen the modal yet
  useEffect( () => {
    if ( medicare && !hasShownCongratsModal ) {
      triggerZs()
    }
  }, [ medicare, hasShownCongratsModal ] )

  const handleClose = () => {
    setCongratsModal( false )
    setHasShownCongratsModal( true )
  }

  const defaultNavigation = () => {
    navigate( `${ENABLE_ACCOUNT_PATH}?afmcatoken=${resetPasswordToken}&email=${formFields.username}&fromSNL=true` )
  }

  return (
    <Modal
      isOpen={congratsModal}
      onClose={handleClose}
      defaultCloseButton={false}
    >
      { confetti && <ReactCanvasConfetti onInit={onInit} /> }
      <div onClick={handleClose} className="cursor-pointer">
        <img
          src={icoXAob}
          alt="close-icon"
          className="absolute right-4 top-4"
        />
      </div>
      <p className="text-center font-light p-3">{`Congratulations! We estimate that your insurance will cover the majority of cost for new CPAP supplies from Aeroflow. Complete our short Sleep Apnea Profile to tell us about your supply needs and see an estimate.`}</p>
      {
        handleNavigate &&
        <button className="a mx-auto px-2 w-full" onClick={() => handleNavigate ? handleNavigate() : defaultNavigation()}>
          {`Want to come back later? Create an account.`}
        </button>
      }

    </Modal>
  )
}

export default ComeBackLaterModal
