import React from 'react'
import { Builder } from '@builder.io/react'
import { extendedSleepProcessAnimation } from 'resources/animations'

type AnimatedGifPromoProps = {
  imageSource: string;
  altText: string;
  leftColumnHeading: string;
  rightColumnText: string;
}

const AnimatedGifPromo = ( props: AnimatedGifPromoProps ): JSX.Element => {
  const { imageSource, altText, leftColumnHeading, rightColumnText } = props

  return (
    <div className="object-contain w-full max-w-4xl mx-auto pb-16">
      <div>
        <img src={imageSource || extendedSleepProcessAnimation} alt={altText || `Animated GIF promo`} />
      </div>
      <div className="flex flex-col md:flex-row gap-10 md:gap-16 items-center max-w-2xl mx-auto px-5">
        {leftColumnHeading &&
            <h2 className="text-aeroflow-navy text-6xl md:text-7xl font-sans font-extrabold pr-2">{leftColumnHeading || `Get Your Free Quote.`}</h2>
        }
        {rightColumnText &&
            <div
              dangerouslySetInnerHTML={{
                __html: rightColumnText
              }}
            />
        }
      </div>
    </div>
  )
}

Builder.registerComponent( AnimatedGifPromo, {
  name: `AnimatedGifPromo`,
  inputs: [
    {
      name: `imageSource`,
      type: `file`,
      allowedFileTypes: [ `gif`, `jpeg`, `jpg`, `png`, `webp` ]
    },
    {
      name: `altText`,
      type: `string`,
      defaultValue: `Animated GIF promo`,
      helperText: `Enter alt text for the image`
    },
    {
      name: `leftColumnHeading`,
      type: `string`,
      defaultValue: `Get Your Free Quote.`,
      helperText: `Large text in the left column`
    },
    {
      name: `rightColumnText`,
      type: `richText`,
      helperText: `Small text in the left column`
    }
  ]
})

export default AnimatedGifPromo
