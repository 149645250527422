import { create } from 'zustand'

// Use isRemoteModalOpen to remotely control the <Modal /> component's isOpen state from any component
type RemoteModalState = {
  isRemoteModalOpen: boolean
  setIsRemoteModalOpen: ( _isOpen: boolean ) => void
}

export const useInsuranceModalRemoteStore = create<RemoteModalState>( ( set ) => ({
  isRemoteModalOpen: false,
  setIsRemoteModalOpen: ( isOpen ) => set({
    isRemoteModalOpen: isOpen
  })
}) )


export const useDoctorDisplayModalStore = create<RemoteModalState>( ( set ) => ({
  isRemoteModalOpen: false,
  setIsRemoteModalOpen: ( isOpen ) => set({
    isRemoteModalOpen: isOpen
  })
}) )

type CongratsModalState = {
  hasShownCongratsModal: boolean
  setHasShownCongratsModal: ( shown: boolean ) => void
}

export const useCongratsModalStore = create<CongratsModalState>( ( set ) => ({
  hasShownCongratsModal: false,
  setHasShownCongratsModal: ( shown ) => set({
    hasShownCongratsModal: shown
  })
}) )