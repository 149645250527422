import { useReactiveVar } from '@apollo/client'
import { blockLeadsDisplay } from 'apollo'
import DoctorBlock from 'components/doctorBlock/DoctorBlock'
import { useDoctorStore } from 'components/doctorBlock/hooks/state'
import LoadingSpinner from 'components/LoadingSpinner'
import { useHealthTapStore } from 'modules/sleepNewLeads/State/healthTapStore'
import React from 'react'
import ComeBackLaterLink from '../ComeBackLaterLink'

function DoctorPage({ ...pageNavigatorProps }) {
  const [ isDoctorLoading, setIsDoctorLoading ] = React.useState<boolean>( true )
  const { healthtapSelected } = useHealthTapStore()
  const { isDoctorComplete, setIsDoctorComplete } = useDoctorStore()
  const leadsDisplayBlocked = useReactiveVar( blockLeadsDisplay )

  const handleDoctorComplete = () => {
    setIsDoctorComplete( true )
    if ( !leadsDisplayBlocked ) {
      // avoid resetting these values on the progress bar if need to display mask fitting page
      pageNavigatorProps.onHandleChange({
        doctorAcceptedFlag: isDoctorComplete ? `1` : ``
      })
    }
  }

  return (
    <div className="sm:mx-auto lg:mx-0 max-w-[672px] relative">
      {isDoctorLoading && (
        // no initial loading state needed for Address Block, use the same loading state as Doctor Block
        <div className="absolute inset-0 bg-white bg-opacity-1 z-10 flex items-start justify-center">
          <LoadingSpinner />
        </div>
      )}

      <DoctorBlock
        onSuccess={handleDoctorComplete}
        onExistingDoctor={handleDoctorComplete}
        headerStyle="font-semibold font-inter text-[#0057A8] sm:text-3xl lg:text-[42px] sm:pb-4 lg:pb-7"
        containerStyle="max-w-2xl"
        doctorSearchStyle="!max-w-none"
        onLoadingComplete={() => { setIsDoctorLoading( false ) }}
        doctorSearchHeader={healthtapSelected ? `It may be necessary for us to contact your physician to obtain documentation required by your insurance provider.` : `We collect your doctor's information as insurance requires documentation, including your original sleep study, to process your order.`}
      />

      <div className="mx-auto w-80 mt-6 space-y-6">
        <button
          className="btn btn-primary"
          onClick={pageNavigatorProps.nextPage}
          disabled={!isDoctorComplete}
        >
          {`Next`}
        </button>
      </div>
      <ComeBackLaterLink className="a py-5 w-full mx-auto underline decoration-deepSleepBlue" />

    </div>
  )
}

export default DoctorPage