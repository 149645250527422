import { useQualifyFormStore } from 'modules/qualifyForm/state'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ENABLE_ACCOUNT_PATH } from 'routes'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'

function ComeBackLaterLink({ className } : { className?: string }) {

  const { formFields } = useQualifyFieldsStore()
  const navigate = useNavigate()
  const { resetPasswordToken } = useQualifyFormStore()

  const handleNavigate = () => {
    navigate( `${ENABLE_ACCOUNT_PATH}?afmcatoken=${resetPasswordToken}&email=${formFields.username}&fromSNL=true` )
  }

  return (
    <div>
      <button className={className ?? `a py-2 w-full text-center lg:text-left underline decoration-deepSleepBlue`} onClick={handleNavigate}>
        {`Want to come back later? Create an account.`}
      </button>
    </div>
  )
}

export default ComeBackLaterLink