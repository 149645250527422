import { Navigate } from 'react-router-dom'

import { PasswordResetForm } from 'components/auth'
import ErrorBanner from './ErrorBanner'
import { useState } from 'react'

const PasswordResetScreen = () => {

  const [ error, setError ] = useState<string>( `` )

  const searchParams = new URLSearchParams( window.location.search )
  const resetPasswordToken = searchParams.get( `token` )
  const resetPasswordEmail = decodeURIComponent( searchParams.get( `email` ) ?? `` ) || null

  if ( !resetPasswordToken ) return <Navigate to="/request-password-reset" />

  return (
    <div className="relative">
      <ErrorBanner error={error} />
      <div className="max-w-2xl px-8 mx-auto flex flex-col items-center md:items-start justify-center my-10">
        <h1 className="text-[35px] text-center">{`Reset Your Password`}</h1>
        <p className="text-graphite text-center my-5 font-light text-lg">{`Let's reset your password. Please enter your email and a new password.`}</p>
        <PasswordResetForm resetPasswordToken={resetPasswordToken} resetPasswordEmail={resetPasswordEmail} setError={setError} />
      </div>
    </div>

  )
}

export default PasswordResetScreen
