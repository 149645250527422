import { useInitialize, useFeatureFlag } from '@kameleoon/react-sdk'
import { useKameleoonStore } from 'stores/kameleoonStore'

export function useKameleoonFeatureFlag( featureFlagKey: string ) {
  const { isInitialized } = useInitialize()
  const { getFeatureFlagVariationKey, isFeatureFlagActive } = useFeatureFlag()
  const { visitorCode } = useKameleoonStore()

  try {
    const isActive = isInitialized() && visitorCode && isFeatureFlagActive( visitorCode, featureFlagKey )

    const variation = isActive
      ? getFeatureFlagVariationKey( visitorCode, featureFlagKey )
      : null

    return {
      isActive,
      variation
    }
  } catch ( e ) {
    return {
      isActive: false,
      variation: null
    }
  }
}