import { Browser, BrowserType, Device, DeviceType, useData, useInitialize, useVisitorCode } from "@kameleoon/react-sdk"
import { useCallback, useEffect } from "react"
import { browserName, isMobile, isTablet } from 'react-device-detect'
import { useKameleoonStore } from "stores/kameleoonStore"

function getBrowserType() {
  // Check for common browsers
  if ( browserName in BrowserType ) {
    return BrowserType[browserName as keyof typeof BrowserType]
  } else {
    return BrowserType.Other
  }
}


export default function KameleoonInit() {

  const { initialize } = useInitialize()
  const { getVisitorCode } = useVisitorCode()
  const { addData } = useData()

  const { setVisitorCode } = useKameleoonStore()

  // -- Waiting for the client initialization using `async/await`

  const init = useCallback( async (): Promise<void> => {
    await initialize()

    // -- Get visitor code
    const visitorCode = getVisitorCode()

    const browserData = new Browser( getBrowserType() )

    const deviceData = new Device( isMobile ? DeviceType.Phone : isTablet ? DeviceType.Tablet : DeviceType.Desktop )

    addData( visitorCode, browserData, deviceData )

    setVisitorCode( visitorCode )

  }, [ initialize, getVisitorCode ] )

  useEffect( () => {
    init()
  }, [ init ] )

  return null
}
