import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StyledErrorWrapper } from 'components/layoutUi'
import { PageTracker } from '..'
import LoadingSpinner from 'components/LoadingSpinner'
import { BrandSelector } from 'components/SNLSelectors'
import NewMachineIneligibleModal from './NewMachineIneligibleModal'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import ActionLink from 'components/SNLSelectors/ActionLink'
import { SNL_MASK_TYPE_PATH } from '../constants'
import { EditSelection } from '../EditSelection/EditSelection'
import { useGetMachineData } from '../hooks/useGetMachineData'
import { useMediaQuery } from 'react-responsive'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'
import ComeBackLaterLink from '../SNLABTest/ComeBackLaterLink'

const MachineBrand = ({onHandleChange, formValues, ...pageProps}) => {
  const [ handleChangeTarget ] = useState( `cpapMachineBrand` )
  const [ displayModal, setDisplayModal ] = useState( false )

  const { machineData, isLoading, errorMessage } = useGetMachineData()

  const handleNext = () => {
    return pageProps.nextPage()
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })


  const handleChange = ( e ) => {
    if ( e.target.name === `cpapMachineBrand` ) {
      onHandleChange({
        ...formValues,
        cpapMachineDmeID: ``,
        cpapMachineModel: ``,
        [e.target.name]: e.target.value
      })
      handleNext()
    } else {
      onHandleChange({
        ...formValues,
        [e.target.name]: e.target.value
      })
    }
  }

  // clear machine model when brand changes
  useEffect( () => {
    onHandleChange({
      cpapMachineModel: ``
    })
  }, [ ] )

  const handleCloseModal = () => {
    setDisplayModal( false )
    pageProps.nextPage()
  }

  const handleChangeMask = () => {
    pageProps.setPage( SNL_MASK_TYPE_PATH )
  }

  const manufacturers = machineData?.map( entry => entry.brand )

  if ( isLoading ) {
    return (
      <div>
        <PageTracker title={`Your Machine`} />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <NewMachineIneligibleModal
        displayModal={displayModal}
        handleCloseModal={handleCloseModal}
      />

      <PageTracker
        title={`Tell us about your machine`}
        description={`To give you the right filters, tubing and water chamber, let us know what machine you’re using`}
      />

      {
        isMobile && <EditSelection activeStep={pageProps.activeStep} />
      }

      <BrandSelector
        heading={`Select your current machine brand`}
        subheader={`Let us know which products you need, and we'll provide an estimated cost through your insurance. Your order won’t be finalized or shipped until you’ve reviewed and confirmed the estimated cost.`}
        name="cpapMachineBrand"
        type="machine"
        brandNames={manufacturers}
        selectedValue={formValues.cpapMachineBrand}
        onChange={( name, value ) => {
          return handleChange({
            target: {
              name,
              value
            }
          })
        }}
        showOnMobile={!handleChangeTarget || handleChangeTarget === `cpapMachineBrand`}
      />

      {
        isMobile &&
        <InsuranceAdvantage
          className="mt-14"
          description={INSURANCE_ADVANTAGE_COPY.machineDescription}
        />
      }

      {formValues?.cpapMaskDmeID &&
        ( <ActionLink
          handleClick={handleChangeMask}
          text={`Change Your Mask`}
        />
        )}

      <StyledErrorWrapper>
        {errorMessage}
      </StyledErrorWrapper>
      <ComeBackLaterLink />
    </div>
  )
}

MachineBrand.propTypes = {
  onHandleChange: PropTypes.func,
  formValues: PropTypes.shape({
    cpapMachineBrand: PropTypes.string,
    cpapMachineModel: PropTypes.string,
    cpapMachineDmeID: PropTypes.oneOfType( [ PropTypes.number, PropTypes.string ] ),
    lastOrderCpapMachine: PropTypes.string,
    intentOrderNewEquipment: PropTypes.string,
    cpapMaskDmeID: PropTypes.string
  }),
  submitLeadNoMachine: PropTypes.func,
  setMachineEligible: PropTypes.func
}

export default MachineBrand