import { useReactiveVar } from '@apollo/client'
import { blockLeadsDisplay } from 'apollo'
import { HealthTapBlock } from 'modules/sleepNewLeads/Components/HealthTapBlock/HealthTapBlock'
import { useHealthTapStore } from 'modules/sleepNewLeads/State/healthTapStore'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import ComeBackLaterLink from '../ComeBackLaterLink'

function Healthtap({ ...pageNavigatorProps }) {
  const { healthtapSelected, setHealthtapSelected } = useHealthTapStore()
  const { formFields } = useQualifyFieldsStore()
  const leadsDisplayBlocked = useReactiveVar( blockLeadsDisplay )
  const navigate = useNavigate()

  const usesMedicaidInsurance = formFields?.insuranceType?.toLowerCase() === `medicaid`

  useEffect( () => {
    if ( leadsDisplayBlocked && window.location.pathname !== `/new-patient-intake/sleep/thank-you` ) navigate( `/new-patient-intake/sleep/thank-you` )

    // if either of these types of insurance we do not want to give users the healthtap option
    if ( usesMedicaidInsurance ) {
      setHealthtapSelected( false )
      pageNavigatorProps?.nextPage()
    }
  }, [] )


  return (
    <div className="sm:mx-auto lg:mx-0 max-w-[672px] relative">
      <HealthTapBlock
        subheader={`We collect this information to speed up processing if you choose to proceed after viewing your estimated cost.`}
      />
      <div className="mx-auto w-80 mt-6 space-y-6">
        <button
          className="btn btn-primary"
          onClick={() => pageNavigatorProps?.nextPage()}
          disabled={healthtapSelected === null}
        >
          {`Next`}
        </button>
      </div>
      <ComeBackLaterLink className="a py-5 w-full mx-auto underline decoration-deepSleepBlue" />
    </div>
  )
}

export default Healthtap