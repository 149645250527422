import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { QualifyHead } from 'components/head'
import BuilderContentFetch from '../../../utils/builderContentFetch'
import { useTokenStore } from 'stores/tokenStore'
import { MY_ACCOUNT_PATH } from 'routes'


export default function ThroughInsurance() {
  const { customerToken } = useTokenStore()
  const navigate = useNavigate()
  // useMedchatWidget( `dvKo2qy0JEq1fVRJ0YZ2Rg` )

  useEffect( () => {
    if ( customerToken ) navigate( MY_ACCOUNT_PATH )
  }, [] )

  return (
    <div className="flex flex-col items-center">
      <QualifyHead /> {/* <!-- Changes the head items for seo when this component is rendered --> */}

      <div className="mx-0 max-w-7xl">
        <BuilderContentFetch
          modelName="page"
          options={{
            url: `/qualify-through-insurance-v2`
          }}
        />
      </div>
    </div>
  )
}