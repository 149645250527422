import React, { ChangeEvent, SyntheticEvent } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import { resetPasswordMutation, generateCustomerTokenMutation } from 'graphql/mutations'
import { Validators } from 'components/forms/components/utils'

import { TextInput } from 'components/forms/components'
import LoadingSpinner from 'components/LoadingSpinner'
import { reportToSentry } from 'utils/reportToSentry'
import { MY_ACCOUNT_PATH } from 'routes'
import { useTokenStore } from 'stores/tokenStore'

interface PasswordResetFormProps {
  resetPasswordToken: string;
  resetPasswordEmail: string|null;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordResetForm = ({ resetPasswordToken, resetPasswordEmail, setError } : PasswordResetFormProps ) => {
  const [ formFields, setFormFields ] = React.useState({
    resetPasswordToken,
    email: resetPasswordEmail ?? ``,
    newPassword: ``,
    newPasswordConfirmation: ``

  })

  const newPasswordRef = React.useRef<HTMLInputElement>( null )
  const newPasswordConfirmationRef = React.useRef<HTMLInputElement>( null )

  const [ resetPassword, {data: resetPasswordData, loading: loadingResetPassword}] = useMutation( resetPasswordMutation, {
    variables: {
      resetPasswordToken: formFields.resetPasswordToken,
      email: formFields.email,
      newPassword: formFields.newPassword
    },
    onError: ( ( error ) => {
      reportToSentry( new Error ( `reset password mutation on -> PasswordResetForm`, {
        cause: error
      }) )
    })
  })

  const [ login, { loading: loadingLogin }] = useMutation( generateCustomerTokenMutation, {
    fetchPolicy: `no-cache`, // Prevent caching user login creds
    variables: {
      email: formFields.email,
      password: formFields.newPassword
    },
    errorPolicy: `all`,
    onError: ( ( error ) => {
      reportToSentry( new Error ( `login mutation during an auto login event -> PasswordResetForm`, {
        cause: error
      }) )
    })
  })

  const { customerToken, setCustomerToken } = useTokenStore()

  const handleSubmit = async ( event: SyntheticEvent ) => {
    event.preventDefault()

    // Final validation of Form Fields and Focus Inputs if they fail
    if ( !Validators.password( formFields.newPassword ) ) {
      return newPasswordRef?.current?.focus()
    }
    if ( !Validators.passwordConfirmation( formFields.newPassword, formFields.newPasswordConfirmation ) ) return newPasswordConfirmationRef?.current?.focus()

    let resetPassswordResult

    try {
      resetPassswordResult = await resetPassword()
    } catch ( err: any ) {

      // eslint-disable-next-line no-console
      console.log( `err.message`, err.message )

      if ( err?.message && err.message.includes( `Cannot set the customer's password` ) ){
        // don't report to sentry
        // the user likely entered an email address that isn't in magento
        return setError( `We're sorry but there was an issue setting your password. Please contact customer service. Error id RP_0913` )
      }

      if ( err?.message && err.message.includes( `The password token is mismatched` ) ){
        // don't report to sentry
        // the user did 1 of 2 things:
        // - 1 the user didn't click on the most recent password reset email in their inbox, this mismatch is that they are trying to use a token that isn't the latest rp token
        // - 2 (less likely) the user has a valid token, and enters a valid email, but the token wasn't issued to the customer with the email

        return setError( `We're sorry but there was an issue setting your password. Please make sure your are clicking on the latest message received in your inbox with the reset password email and click on the link in that email. If problems persist please contact customer service. Error id RP_0914` )
      }

      // only report to sentry for now if it's an unknown reason - the known reasons needn't be errors
      reportToSentry( new Error( `resetPassword mutation error in PasswordResetForm RP_0915`, {
        cause: err
      }) )

      return setError( `We're sorry but there was an issue setting your password. Please contact customer service. Error id RP_0915` )

    }

    // @NOTE - if we see this error in sentry we need to understand why it's coming in.
    if ( resetPassswordResult?.errors ) {

      reportToSentry( new Error( `resetPassword mutation error in PasswordResetForm RP_0916` ), {
        graphqlErrors: JSON.stringify( resetPassswordResult.errors )
      })

      return setError( `We're sorry but there was an issue setting your password. Please contact customer service. Error id RP_0916` )

    }


    const loginResult = await login()

    if ( loginResult?.data?.generateCustomerToken?.token ) {
      setCustomerToken( loginResult.data.generateCustomerToken.token )
    }
  }

  const handleChange = ( event: ChangeEvent<HTMLInputElement> ) => {
    setFormFields({
      ...formFields,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  if ( loadingResetPassword || loadingLogin ) {
    return (
      <div className="my-8">
        <LoadingSpinner />
      </div>
    )
  }

  if ( resetPasswordData?.resetPassword ) {
    return (
      <>
        <p className="mb-4" >{`Your password has been reset!`}</p>

        {customerToken
          ? <Link to={MY_ACCOUNT_PATH} className="block px-4 py-2 text-xs text-center rounded-full text-white bg-deepSleepBlue cursor-pointer">{`GO TO MY ACCOUNT`}</Link>
          : <Link to="/login" className="block px-4 py-2 text-xs text-center rounded-full text-white bg-deepSleepBlue cursor-pointer">{`LOGIN`}</Link>
        }
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="my-5 w-full flex flex-col">
      <TextInput
        name="email"
        label="Email"
        type="email"
        value={formFields.email}
        onChange={handleChange}
        required
        readOnly={Boolean( resetPasswordEmail )}
      />

      <TextInput
        name="newPassword" label="New Password" type="password"
        value={formFields.newPassword}
        onChange={handleChange}
        required
        validator={{
          function: Validators.password,
          failureMessage: `Your password must contain lower case, upper case and at least one number or special character.`
        }}
        reference={newPasswordRef}
      />

      <TextInput
        name="newPasswordConfirmation" label="Confirm New Password" type="password"
        value={formFields.newPasswordConfirmation}
        onChange={handleChange}
        required
        validator={{
          function: newPasswordConfirmationValue => {
            return Validators.passwordConfirmation( formFields.newPassword, newPasswordConfirmationValue )
          },
          failureMessage: `Your passwords don't match. Please double check your passwords.`
        }}
        reference={newPasswordConfirmationRef}
      />
      <br></br>
      <button className="btn btn-primary mx-auto md:mx-0" type="submit">
        {`Reset My Password`}
      </button>
    </form>
  )
}
PasswordResetForm.propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
  resetPasswordEmail: PropTypes.oneOf( [ PropTypes.string, null ] ).isRequired
}

export default PasswordResetForm
