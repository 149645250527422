import React from 'react'
import { PageTracker } from '..'
import { useSNLStore } from '../State/snlStorage'
import { useNavigate } from 'react-router-dom'
import { machineImages, maskImages } from 'modules/sleepNewLeads/constants'
import CheckIcon from '../../../components/SNLSelectors/CheckIcon'
import { SNL_MASK_TYPE_PATH } from '../constants'
import ComeBackLaterLink from '../SNLABTest/ComeBackLaterLink'

interface SnlOrderSummaryProps {
  nextPage: () => void;
}

interface EditSelectionState {
  snlEditSelection: () => {
    label: string,
    value: string,
    step: string,
    path: string
  }[],
  doesNotWantMask: boolean
}

type OrderSummaryItemProps = {
  value: string
  path: string
  step: string
}

export const OrderSummaryItem = ( orderItem: OrderSummaryItemProps ) => {
  const navigate = useNavigate()

  const imgSrc = ( ) => {
    return orderItem.step === `cpapMaskModel` ?
      maskImages.find( ( item ) => item.mask === orderItem.value )?.imgSrc[0] :
      machineImages.find( ( item ) => item.machine === orderItem.value )?.imgSrc[0]
  }

  return (
    <div className="flex grow basis-1/4 items-center sm:mb-5 lg:mb-6">
      <div className="relative sm:w-[100px] sm:h-[100px] lg:w-[175px] lg:h-[175px] cursor-pointer border border-deepSleepBlue flex rounded-md overflow-hidden mr-4">
        <CheckIcon className="" />
        <img
          src={imgSrc()}
          alt={orderItem?.value}
          onClick={() => navigate( orderItem?.path )}
          className="object-contain absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-3"
        />
      </div>
      <div>
        <p className="font-bold">{`${orderItem?.step === `cpapMaskModel` ? `Mask` : `Machine`}: `}<span className="font-normal">{orderItem?.value}</span></p>
        <p className="text-deepSleepBlue cursor-pointer underline" onClick={() => navigate( orderItem?.path )} >
          {`Edit`}
        </p>
      </div>
    </div>
  )
}

const SnlOrderSummary = ({ ...pageProps }: SnlOrderSummaryProps ) : JSX.Element => {
  const { snlEditSelection, doesNotWantMask } = useSNLStore() as EditSelectionState
  const selection = snlEditSelection().filter( item => [ `cpapMaskModel`, `cpapMachineModel` ].includes( item.step ) )
  const navigate = useNavigate()

  return (
    <div>
      <PageTracker
        title={`Your Equipment`}
      />
      <div className="pt-5 flex flex-wrap flex-col lg:gap-10">
        {selection?.length > 0 &&
          selection.map( ( item ) => (
            <OrderSummaryItem
              key={item.value}
              value={item.value}
              path={item.path}
              step={item.step}
            />
          ) )
        }
        {doesNotWantMask && (
          <div className="flex items-center gap-2">
            <p className="font-bold">{`No mask selected`}</p>
            <p
              onClick={() => navigate( SNL_MASK_TYPE_PATH )}
              className="text-deepSleepBlue cursor-pointer underline"
            >
              {`Edit`}
            </p>
          </div>
        )}
      </div>

      <p className="pb-5 sm:pt-16 lg:pt-20 sm:text-center lg:text-left">{`You will have the ability to remove any unwanted mask or machine supplies before finalizing your order.`}</p>

      <div className="sm:text-center lg:text-left">
        <button
          className="btn-primary"
          onClick={pageProps.nextPage}
        >
          {`Next`}
        </button>
      </div>
      <ComeBackLaterLink />
    </div>
  )
}

export default SnlOrderSummary