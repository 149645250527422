import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { StyledErrorWrapper } from 'components/layoutUi'
import { useReactiveVar } from '@apollo/client'
import { blockLeadsDisplay } from 'apollo'
import AddressBlock, { overrideAddressBlockStyle, useAddressStore } from 'components/addressBlock'
import DoctorBlock from 'components/doctorBlock/DoctorBlock'
import { useDoctorStore } from 'components/doctorBlock/hooks/state'
import LoadingSpinner from 'components/LoadingSpinner'
import { HealthTapBlock } from '../Components/HealthTapBlock/HealthTapBlock'
import { useQualifyFieldsStore } from 'stores/qualifyFieldsStore'
import { useHealthTapStore } from '../State/healthTapStore'
import { HEALTHTAP_FEATURE_FLAG } from 'routes'
import ComeBackLaterLink from '../SNLABTest/ComeBackLaterLink'

export default function DoctorAddressCollection({onSubmit, displaySubmitError, isSleepNewLeads, ...pageNavigatorProps}) {
  const { isDoctorComplete, setIsDoctorComplete } = useDoctorStore()
  const [ isDoctorLoading, setIsDoctorLoading ] = useState( true )
  const isAddressComplete = useAddressStore( state => state.isAddressComplete )
  const leadsDisplayBlocked = useReactiveVar( blockLeadsDisplay )

  const { healthtapSelected, setHealthtapSelected } = useHealthTapStore()

  const { formFields } = useQualifyFieldsStore()

  const usesMedicaidInsurance = formFields?.insuranceType?.toLowerCase() === `medicaid`

  useEffect( () => {
    if ( leadsDisplayBlocked && window.location.pathname !== `/new-patient-intake/sleep/thank-you` ) pageNavigatorProps.nextPage()

    // if either of these types of insurance we do not want to give users the healthtap option
    // also set to false if feature flag is disabled
    if ( usesMedicaidInsurance || !HEALTHTAP_FEATURE_FLAG ) setHealthtapSelected( false )
  }, [] )

  useEffect( () => {
    if ( !leadsDisplayBlocked ) {
      // avoid resetting these values on the progress bar if need to display mask fitting page
      pageNavigatorProps.onHandleChange({
        doctorAcceptedFlag: isDoctorComplete ? `1` : ``
      })
    }
  }, [ isDoctorComplete ] )

  useEffect( () => {
    // avoid resetting these values on the progress bar if need to display mask fitting page
    if ( !leadsDisplayBlocked ) {
      pageNavigatorProps.onHandleChange({
        addressAcceptedFlag: isAddressComplete ? `1` : ``
      })
    }
  }, [ isAddressComplete ] )

  overrideAddressBlockStyle({
    headerStyle: `font-semibold font-inter text-[#0057A8] text-center sm:text-3xl lg:text-[42px] sm:pb-4 lg:pb-7`,
    containerStyle: ``,
    wrapperStyle: `max-w-none text-center`,
    view: {
      editActionClassName: `underline cursor-pointer text-deepSleepBlue`
    }
  })

  return (
    <div className="sm:mx-auto lg:mx-0 max-w-xl relative">
      {isDoctorLoading && (
        // no initial loading state needed for Address Block, use the same loading state as Doctor Block
        <div className="absolute inset-0 bg-white bg-opacity-1 z-10 flex items-start justify-center">
          <LoadingSpinner />
        </div>
      )}

      {/* do not show healthtap block if medicaid selected as primary insurance in QF  */}
      {!usesMedicaidInsurance && HEALTHTAP_FEATURE_FLAG && (
        <HealthTapBlock
          subheader={`We collect this information to speed up processing if you choose to proceed after viewing your estimated cost.`}
        />
      )}

      <DoctorBlock
        onSuccess={() => { setIsDoctorComplete( true ) }}
        onExistingDoctor={() => { setIsDoctorComplete( true ) }}
        headerStyle="font-semibold font-inter text-[#0057A8] sm:text-3xl lg:text-[42px] sm:pb-4 lg:pb-7"
        containerStyle="max-w-2xl"
        doctorSearchStyle="!max-w-none"
        onLoadingComplete={() => { setIsDoctorLoading( false ) }}
        doctorSearchHeader={healthtapSelected ? `It may be necessary for us to contact your physician to obtain documentation required by your insurance provider.` : `We collect your doctor's information as insurance requires documentation, including your original sleep study, to process your order.`}
      />

      <AddressBlock
        addressType=""
        subHeaderText={`Your order will only be shipped after you have reviewed your estimated cost and confirmed your order.`}
        snlHeaderText={`Your Shipping Address`}
        hideAddressConfirm
      />

      {displaySubmitError && (
        <StyledErrorWrapper>
          {`We're sorry but our servers have encountered an issue processing this submission. Please call a patient care specialist at ${AF_SUPPORT_PHONE_NUMBER} for help.`}
        </StyledErrorWrapper>
      )}
      <div className="mx-auto w-80 mt-6 space-y-6">
        <button
          className={`btn ${isSleepNewLeads ? `btn-primary` : `btn-secondary `}`}
          onClick={onSubmit}
          disabled={!isDoctorComplete || !isAddressComplete || healthtapSelected === null}
        >
          {`${isSleepNewLeads ? `Submit` : `Complete Form`}`}
        </button>
      </div>
      <ComeBackLaterLink className="a py-5 w-full mx-auto underline decoration-deepSleepBlue" />
    </div>
  )
}

DoctorAddressCollection.propTypes = {
  onSubmit: PropTypes.func,
  displaySubmitError: PropTypes.bool,
  onHandleChange: PropTypes.func,
  isSleepNewLeads: PropTypes.bool
}