import React from 'react'
import { Builder } from '@builder.io/react'
import QualifyForm from 'modules/qualifyForm'

type PaidAdsQualifyFormProps = {
  heading: string;
  bodyText: string;
  backgroundImage: string;
  backgroundColor: string;
  qualifyFormClassNames?: string;
}

const PaidAdsQualifyForm = ( props: PaidAdsQualifyFormProps ): JSX.Element => {
  const {
    heading,
    bodyText,
    backgroundImage,
    backgroundColor,
    qualifyFormClassNames
  } = props

  // using a linear gradient to add an overlay to the background image or choose a solid color for the background
  const overlayColor = backgroundColor ? `linear-gradient(0deg, ${backgroundColor}, ${backgroundColor})` : ``
  const backgroundImageUrl = backgroundImage ? `url(${backgroundImage})` : ``

  return (
    <div
      className="!bg-cover !bg-no-repeat w-full relative"
      style={{
        backgroundImage: [ overlayColor, backgroundImageUrl ].filter( Boolean ).join( `, ` )
      }}
    >
      <div className="relative z-10 flex flex-col items-center gap-5 lg:gap-10 py-20 px-10 mx-auto justify-center">
        <h1 className="text-white justify-center text-4xl lg:text-7xl font-normal leading-snug lg:leading-tight text-center my-auto md:border-0 border-b-2 border-white pb-5">
          {heading || `PAP Supplies up to 100% coverage through Medicare`}
        </h1>
        <div className="flex flex-col items-left lg:items-center justify-center">
          {/* use rich text editor in builder */}
          {/* use parent selector to style the text generated by the rich text editor */}
          <div
            className="[&>p]:text-white [&>p]:font-light [&>p]:text-xl [&>p]:leading-relaxed lg:[&>p]:text-2xl border-0 md:border-b-2 md:border-white pb-5"
            dangerouslySetInnerHTML={{
              __html: bodyText || `We proudly work with Medicare plans!`
            }}
          />
          <div className="flex-2 text-center bg-secondaryBlue-tint2 text-black flex-col flex items-center mt-5 bg-opacity-70">
            <QualifyForm classNames={qualifyFormClassNames || `sm:px-6 md:px-10 pt-9`} />
          </div>
        </div>
      </div>
    </div>
  )
}

Builder.registerComponent( PaidAdsQualifyForm, {
  name: `PaidAdsQualifyForm`,
  inputs: [
    {
      name: `heading`,
      type: `text`,
      defaultValue: `PAP Supplies up to 100% coverage through Medicare`,
      required: true
    },
    {
      name: `bodyText`,
      type: `richText`,
      defaultValue: `We proudly work with Medicare plans!`,
      required: true
    },
    {
      name: `backgroundImage`,
      type: `file`,
      allowedFileTypes: [ `jpeg`, `jpg`, `png`, `webp` ],
      helperText: `Upload a background image (optional)`
    },
    {
      name: `backgroundColor`,
      type: `color`,
      defaultValue: `#0057a8`,
      helperText: `Choose a background color (used if no image is provided or as overlay color)`
    },
    {
      name: `qualifyFormClassNames`,
      type: `string`,
      defaultValue: `sm:px-6 md:px-10 pt-9`,
      advanced: true,
      helperText: `Custom classes for the qualify form container`
    }
  ]
})

export default PaidAdsQualifyForm
