import { Tile } from 'components/tile'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAccountStore } from '../state'
import { myInvoiceId } from '../constants'
import { useTokenStore } from 'stores/tokenStore'
import { useKameleoonFeatureFlag } from 'hooks/useKameleoonFeatureFlag'

export type BalanceAvailableType = {
  data: {
    current_balance_flag: boolean,
    current_balance: number,
    patient_hash: string,
    division: string
  }[]
}

export type ActiveInvoiceResponse = {
  activeInvoice?: boolean,
  hash?: string
}

export type BillPayInvoiceResponse = {
  billPayAmount?: string,
  billPayUrl?: string
}

function Invoice() : JSX.Element | null {
  const { customer, activeInvoice, billPayInvoice } = useAccountStore()
  const navigate = useNavigate()
  const { customerToken } = useTokenStore()

  const { isActive } = useKameleoonFeatureFlag( `aob_payments_alokai_redirect` )

  const alokaiPaymentsPath = `${process.env.REACT_APP_ALOKAI_PAYMENTS_URL}/payments?sgh=${activeInvoice?.data?.hash}&m2Token=${customerToken}`
  const paymentPortalPath = `/payments/pay?sgh=${activeInvoice?.data?.hash}`

  if ( ( activeInvoice.error && billPayInvoice.error ) || ( ( !activeInvoice?.data?.activeInvoice || !activeInvoice?.data?.hash ) && ( !billPayInvoice?.data?.billPayUrl || !billPayInvoice?.data?.billPayAmount ) ) ) return null

  return (
    <Tile headerContent="Invoice" id={myInvoiceId}>
      <>
        {activeInvoice?.data?.activeInvoice && activeInvoice?.data?.hash && (
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => isActive ? window.open( alokaiPaymentsPath, `_self` ) : navigate( paymentPortalPath )}
            >
              {`Complete My Order`}
            </button>
          </div>
        )}
        {( billPayInvoice?.data?.billPayUrl && billPayInvoice?.data?.billPayAmount &&
          <div>
            <p className="pt-10 pb-1 text-left">
              <span className="capitalize">{`${customer?.data?.firstname.toLocaleLowerCase()}`}</span>
              {`, you currently have a bill for $${billPayInvoice?.data?.billPayAmount}. Click the button below to pay all or a portion now.`}
            </p>
            <button
              className="btn btn-secondary"
              onClick={() => { window.open( billPayInvoice?.data?.billPayUrl, `_blank` ) }}
            >
              {`Pay My Bill`}
            </button>
          </div>
        )}
      </>
    </Tile>
  )
}

export default Invoice