import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { PageTracker } from '..'
import LoadingSpinner from 'components/LoadingSpinner'
import { MaskTypeSelector } from 'components/SNLSelectors'
import { useGetMaskData } from '../hooks/useGetMaskData'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import ActionLink from 'components/SNLSelectors/ActionLink'
import { EditSelection } from '../EditSelection/EditSelection'
import { useMediaQuery } from 'react-responsive'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'
import ComeBackLaterModal from 'components/comeBackLaterModal'
import ComeBackLaterLink from '../SNLABTest/ComeBackLaterLink'

const MaskType = ({onHandleChange, formValues, ...pageProps}) => {
  const { isLoading, maskData } = useGetMaskData()

  const handleNext = () => {
    return pageProps.nextPage()
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const [
    maskTypes
  ] = useMemo( () => {
    const typeNames = maskData?.map( ({sleep_mask_category}) => { return sleep_mask_category })

    return [ typeNames ]
  }, [ maskData, formValues ] )

  const handleChange = ( e ) => {
    if ( e.target.name === `cpapMaskType` ) {
      // if mask type changes reset the all mask inputs
      onHandleChange({
        [e.target.name]: e.target.value,
        cpapMaskBrand: ``,
        cpapMaskDmeID: ``,
        cpapMaskModel: ``,
        cpapMaskSize: ``
      })
    } else {
      onHandleChange({
        [e.target.name]: e.target.value
      })
    }
    handleNext()
  }

  if ( isLoading ) {
    return (
      <div>
        <PageTracker
          title={`Your Mask`}
        />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="flex flex-row min-h-screen">
      <ComeBackLaterModal confetti />

      <div>
        <PageTracker
          title={`Your Mask`}
          description={`To get started, select a mask so that we can provide you with an estimated cost through insurance.`}
        />

        {
          isMobile && <EditSelection activeStep={pageProps.activeStep} />
        }

        <MaskTypeSelector
          heading={`Select your current mask type`}
          subheader={`Let us know which products you need, and we'll provide an estimated cost through your insurance. Your order won’t be finalized or shipped until you’ve reviewed and confirmed the estimated cost.`}
          name="cpapMaskType"
          selectedValue={formValues.cpapMaskType}
          maskTypes={maskTypes}
          onChange={handleChange}
          showOnMobile
        />

        {
          isMobile &&
            <InsuranceAdvantage
              className="mt-14"
              description={INSURANCE_ADVANTAGE_COPY.maskDescription}
            />
        }

        <ActionLink
          handleClick={pageProps.onHandleDoesNotWantMask}
          text={`Don't want a mask?`}
        />
        <ComeBackLaterLink />
      </div>
    </div>
  )
}

MaskType.propTypes = {
  onHandleChange: PropTypes.func,
  history: PropTypes.object,
  getMaskFittingContent: PropTypes.func,
  formValues: PropTypes.shape({
    cpapMaskType: PropTypes.string,
    cpapMaskModel: PropTypes.string,
    cpapMaskDmeID: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    cpapMaskBrand: PropTypes.string,
    cpapMaskSize: PropTypes.string,
    lastOrderCpapMask: PropTypes.string,
    lastOrderCpapMaskHeadgear: PropTypes.string,
    satisfiedWithCurrentMask: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] )
  })
}


export default MaskType