import { useState, useEffect } from 'react'
import useGetAobDetail from './hooks/useGetAobDetail'
import PageNavigator from 'components/pageNavigator'
import { AOB_PAGES, AOB_PAGES_WITH_SCHEDULING } from 'modules/aobPayments/constants'
import { OrderSummary, Payments, ThankYou } from './pages'
import ClinicalScheduling from 'modules/clinicalScheduling/ClinicalScheduling'
import { AobError, AobLoader, LogoWithAccount, NoEligibleInvoice } from './components'
import useGetInvoice from './hooks/useGetInvoice'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { SleepLinearGradient } from 'components/sleepLinearGradient'
import { useTokenStore } from 'stores/tokenStore'
import { useKameleoonFeatureFlag } from 'hooks/useKameleoonFeatureFlag'
import { getHash } from "modules/clinicalScheduling/utils"

const AobPaymentsPortal = ({ type }) => {

  // useMedchatWidget( `Jg8-7qBt006HbRa-_iDamg` )

  const [ payLater, setPayLater ] = useState( false )
  const [ aobIsCompleted, setAobIsCompleted ] = useState( false )
  const [ error, setError ] = useState( `` )
  const [ removedItems, setRemovedItems ] = useState( [] )
  const [ notInterested, setNotInterested ] = useState( null )
  const [ paymentCompleted, setPaymentCompleted ] = useState( false )

  const { invoice, setInvoice, invoiceIneligible } = useGetInvoice( type, setError )
  const aobDetail = useGetAobDetail( setError )

  const { isActive } = useKameleoonFeatureFlag( `aob_payments_alokai_redirect` )
  const { customerToken } = useTokenStore()

  useEffect( () => {
    const hash = getHash()
    // check for the aob payments alokai redirect feature flag, setting redirect here for users who land on the page via hashed link.(Direct link or email link)
    if ( isActive && hash && invoice?.pap_setup_type === `Intake` ) {
      const url = `${process.env.REACT_APP_ALOKAI_PAYMENTS_URL}/payments?sgh=${hash}${customerToken ? `&m2Token=${customerToken}` : ``}`
      window.open( url, `_self` )
    }
  }, [ isActive, getHash, invoice ] )

  if ( error || ( invoice !== null && Object.keys( invoice ).length === 0 ) ) return <AobError message={error ?? `Oops... We are having trouble getting your price estimate. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER}`} />
  if ( invoiceIneligible ) return <NoEligibleInvoice />

  if ( invoice === null || aobDetail === undefined ) return <AobLoader message={`Please hold tight while we get your estimate...`} />

  return (
    <>
      <SleepLinearGradient height={34} />
      <div className="absolute z-10 top-3 left-0 right-0 max-w-2xl px-4 mx-auto mt-[34px]">
        <LogoWithAccount />
      </div>
      <PageNavigator
        pageNames={invoice?.scheduling_required_flag ? AOB_PAGES_WITH_SCHEDULING : AOB_PAGES}
        pageClassName="max-w-full mx-auto bg-[#F7F7F7]"
        childSharedProps={{
          type,
          invoice,
          payLater,
          aobIsCompleted,
          removedItems
        }}
      >
        <OrderSummary
          setPayLater={setPayLater}
          aobContent={aobDetail}
          setAobIsCompleted={setAobIsCompleted}
          setError={setError}
          setInvoice={setInvoice}
          setRemovedItems={setRemovedItems}
          setNotInterested={setNotInterested}
        />
        <Payments setPaymentCompleted={setPaymentCompleted} />
        {invoice?.scheduling_required_flag && <ClinicalScheduling />}
        <ThankYou
          notInterested={notInterested}
          paymentCompleted={paymentCompleted}
        />
      </PageNavigator>
    </>
  )

}

export default AobPaymentsPortal