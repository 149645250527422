import React, { useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BrandSelector } from 'components/SNLSelectors'
import { PageTracker } from '..'
import LoadingSpinner from 'components/LoadingSpinner'
import { EditSelection } from '../EditSelection/EditSelection'
import { useMediaQuery } from 'react-responsive'
import ActionLink from 'components/SNLSelectors/ActionLink'
import { INSURANCE_ADVANTAGE_COPY } from '../InsuranceAdvantage/constants'
import InsuranceAdvantage from '../InsuranceAdvantage/InsuranceAdvantage'
import { useBaseEquipmentStore } from '../State/baseEquipmentStore'
import { SNL_MASK_TYPE_PATH } from '../constants'
import ComeBackLaterLink from '../SNLABTest/ComeBackLaterLink'

const MaskBrand = ({ onHandleChange, formValues, ...pageProps }: any ) => {
  const { isLoading, maskData } = useBaseEquipmentStore()
  const navigate = useNavigate()

  const maskBrands = useMemo( () => {
    const category = maskData.find( item => item.sleep_mask_category === formValues?.cpapMaskType )
    return category?.brandsAvailable.map( brand => brand.brand ) || []
  }, [ maskData, formValues ] )

  const handleNext = () => {
    return pageProps.nextPage()
  }

  const isMobile = useMediaQuery({
    query: `(max-width: 976px)`
  })

  const handleChange = ( name: string, value: string ) => {
    if ( name === `cpapMaskBrand` ) {
      onHandleChange({
        [name]: value,
        cpapMaskDmeID: ``,
        cpapMaskModel: ``,
        cpapMaskSize: ``
      })
    } else {
      onHandleChange({
        [name]: value
      })
    }
    handleNext()
  }

  // ensure a mask type is selected before navigating to the mask-brand page to avoid empty results
  useEffect( () => {
    if ( !formValues.cpapMaskType ) return navigate( SNL_MASK_TYPE_PATH )
  }, [ ] )

  // clear mask model when brand changes
  useEffect( () => {
    onHandleChange({
      cpapMaskModel: ``
    })
  }, [ formValues.cpapMaskBrand ] )

  if ( isLoading ) {
    return (
      <div>
        <PageTracker title={`Your Mask`} />
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <PageTracker
        title={`Your Mask`}
        description={`To get started, select a mask so that we can provide you with an estimated cost through insurance.`}
      />
      {
        isMobile && <EditSelection activeStep={pageProps.activeStep} />
      }

      <BrandSelector
        heading={`Select your current mask brand`}
        subheader={`Let us know which products you need, and we'll provide an estimated cost through your insurance. Your order won’t be finalized or shipped until you’ve reviewed and confirmed the estimated cost.`}
        name="cpapMaskBrand"
        type="mask"
        onChange={( name, value ) => {
          return handleChange(
            name,
            value
          )
        }}
        brandNames={maskBrands}
        selectedValue={formValues.cpapMaskBrand}
        defaultLabel="Select a mask manufacturer"
        showOnMobile
      />

      {
        isMobile &&
            <InsuranceAdvantage
              className="mt-14"
              description={INSURANCE_ADVANTAGE_COPY.maskDescription}
            />
      }

      <ActionLink
        handleClick={pageProps.onHandleDoesNotWantMask}
        text={`Don't want a mask?`}
      />
      <ComeBackLaterLink />

    </div>
  )
}

export default MaskBrand