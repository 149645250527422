import React, { useState } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import DoctorResult from '../types/DoctorResult'
import DoctorOption from './DoctorOption'
import { updateDoctor } from '../utils/utils'
import { reportToSentry } from 'utils/reportToSentry'
import DoctorInfo from '../types/DoctorInfo'
import { AF_SUPPORT_PHONE_NUMBER } from 'constants/phoneNumbers'
import { getAuthHeader } from 'utils/auth/helpers'
import { refreshTrackerDataStore } from 'stores'
import { magentoStoreIdSleep, magentoWebsiteIdSleep } from 'constants/magentoIds'

type DoctorResults = {
  searchLoading: boolean;
  searchResults: DoctorResult[] | null;
  searchError: string;
  zipDisplay: boolean;
  handleSuccess: ( _newDoctor: DoctorInfo ) => void;
  submitLoading: boolean;
  setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function DoctorResults({ searchLoading, searchResults, searchError, zipDisplay, handleSuccess, submitLoading, setSubmitLoading } : DoctorResults ) : JSX.Element | null {

  const [ error, setError ] = useState<string>( `` )
  const { refreshTrackerData } = refreshTrackerDataStore.getState()

  const defaultError = `Oops... we encountered an error attempting to update your doctor. Please try again or reach out to customer service at ${AF_SUPPORT_PHONE_NUMBER} if this issue persists.`

  const submitSelectDoctor = async ( selectedDoctor: DoctorResult ) => {
    setSubmitLoading( true )
    setError( `` )
    const updateDoctorResponse = await updateDoctor({
      doctorFirstName: selectedDoctor.first_name,
      doctorLastName: selectedDoctor.last_name,
      doctorPhone: selectedDoctor.telephone_number,
      doctorNPI: selectedDoctor.npi,
      profitCenterPK: `5`,
      sourceUrl: window.location.href,
      magentoWebsiteId: magentoWebsiteIdSleep,
      magentoStoreId: magentoStoreIdSleep
    }, getAuthHeader() )
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Update Doctor Failed`, {
          cause: error
        }) )
        setSubmitLoading( false )

        return setError( defaultError )
      })

    setSubmitLoading( false )

    if ( updateDoctorResponse?.meta?.status === `OK` ) {
      refreshTrackerData()
      return handleSuccess({
        firstName: selectedDoctor.first_name,
        lastName: selectedDoctor.last_name,
        phone: selectedDoctor.telephone_number,
        npi: selectedDoctor.npi.toString()
      })
    }

    setError( defaultError )
  }

  if ( zipDisplay ) return null

  if ( searchError ) return (
    <div className="my-10">
      <p className="text-error font-light">{searchError}</p>
    </div>
  )

  if ( searchLoading || submitLoading ) return (
    <div className="my-10">
      <LoadingSpinner />
    </div>
  )

  if ( !searchResults ) return null

  if ( !searchResults.length ) return (
    <div className="my-10">
      <p className="font-light">{`No doctors fit this search criteria...`}</p>
    </div>
  )

  return (
    <>
      <div className="flex flex-col gap-7 max-h-96 overflow-auto px-5 my-10 mx-auto">
        {
          searchResults.map( ( doctorResult : DoctorResult ) => {
            return (
              <DoctorOption
                key={doctorResult.npi}
                doctorResult={doctorResult}
                selectDoctor={submitSelectDoctor}
              />
            )
          })
        }
      </div>
      {
        error &&
        <p className="text-error font-light">{error}</p>
      }
    </>
  )
}

export default DoctorResults