import React from 'react'
import { Builder } from '@builder.io/react'

interface MinimalFooterWithCtaProps {
  footerText: string;
  buttonText: string;
  buttonLink: string;
  buttonAction: string;
  backgroundColor: string;
  isExternalLink: boolean;
}

export const MinimalFooterWithCta = ({
  footerText,
  buttonText,
  buttonLink,
  buttonAction = `link`,
  backgroundColor = ``,
  isExternalLink = false
}: MinimalFooterWithCtaProps ) => {

  const getHref = () => {
    switch ( buttonAction ) {
    case `email`:
      return `mailto:${buttonLink}`
    case `phone`:
      return `tel:${buttonLink}`
    default:
      return buttonLink
    }
  }

  const btnClass = `text-deepSleepBlue bg-white rounded-full text-center text-xl inline-block py-2 px-9 active:bg-black active:text-white`

  const renderButton = () => {
    const linkProps = isExternalLink ? {
      target: `_blank`,
      rel: `noopener noreferrer`
    } : {}

    return (
      <a
        href={getHref()}
        className={btnClass}
        {...( buttonAction === `link` ? linkProps : {})}
      >
        {buttonText || `Contact Us`}
      </a>
    )
  }

  return (
    <div
      className="py-8 px-4 text-center" style={{
        backgroundColor
      }}
    >
      <div className="mx-auto max-w-4xl">
        <div
          className="font-inter text-white mb-6"
          dangerouslySetInnerHTML={{
            __html: footerText || `Contact us at 800-480-5491 if you have any questions about getting PAP supplies through insurance.`
          }}
        />
        {buttonText && buttonLink && renderButton()}
      </div>
    </div>
  )
}

Builder.registerComponent( MinimalFooterWithCta, {
  name: `Minimal Footer With CTA`,
  inputs: [
    {
      name: `footerText`,
      type: `richText`,
      required: true
    },
    {
      name: `buttonText`,
      type: `string`,
      defaultValue: `Email Us`
    },
    {
      name: `buttonLink`,
      type: `string`,
      defaultValue: `mailto:info@aeroflowsleep.com`
    },
    {
      name: `isExternalLink`,
      type: `boolean`,
      defaultValue: false
    },
    {
      name: `buttonAction`,
      type: `enum`,
      enum: [
        {
          label: `Link`,
          value: `link`
        },
        {
          label: `Email Address`,
          value: `email`
        },
        {
          label: `Phone Number`,
          value: `phone`
        }
      ],
      defaultValue: `email`
    },
    {
      name: `backgroundColor`,
      type: `color`,
      defaultValue: `#0057a8`
    }
  ]
})

export default MinimalFooterWithCta
